import {useState, useCallback, useContext} from 'react'
import {AuthContext} from '../context/AuthContext'
import {useHttp} from '../hooks/http.hook'
import {useMessage} from '../hooks/message.hook'
import axios from "axios";

export const useAPI = () => {
    const {request} = useHttp()
    const {token} = useContext(AuthContext)
    const {message} = useMessage()

  const deleteList = useCallback(async (listId) => {
    if (!listId) return
    try {
        const response = await request(`/api/list/delete`, 'POST', {id:listId}, {
            Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])


  const deleteStartup = useCallback(async (startupId) => {
    if (!startupId) return
    try {
        const response = await request(`/api/startup/delete`, 'POST', {id:startupId}, {
            Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])

  const deleteStartupFromList = useCallback(async (listId, removedStartups) => {
    if (!listId) return
    try {
        const response = await request('/api/list/removeitems', 'post', {listId: listId, removedStartups: removedStartups}, {
          Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])

  const multiDeleteStartup = useCallback(async (startupIds) => {
    if (!startupIds || startupIds.length === 0) return
    try {
        const response = await request(`/api/startup/multidelete`, 'POST', {ids:startupIds}, {
            Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])


  const restoreStartup = useCallback(async (startupId) => {
    if (!startupId) return
    try {
        const response = await request(`/api/startup/restore`, 'POST', {id:startupId}, {
            Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])

  const multiRestoreStartup = useCallback(async (startupIds) => {
    if (!startupIds || startupIds.length === 0) return
    try {
        const response = await request(`/api/startup/multirestore`, 'POST', {ids:startupIds}, {
            Authorization: `Bearer ${token}`
        })
        message.success()
        return response
      } catch (e) {
        message.error(e.message || 'Ошибка')
      }
  }, [])


  // const importStarups = useCallback(async (formData) => {
  //   try {
  //       const response = await request(`/api/startup/cvsimport`, 'POST', formData, {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data"
  //       })
  //       message.success()
  //       return response
  //     } catch (e) {
  //       message.error(e.message || 'Ошибка')
  //     }
  // }, [])

  const importStarups = (formData) => {
    return axios.post(`/api/startup/cvsimport`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`
      },
    });
  };

  ///const clearError = useCallback(() => setError(null), [])

  return { deleteList, deleteStartup, restoreStartup, multiDeleteStartup, multiRestoreStartup, importStarups, deleteStartupFromList}
}