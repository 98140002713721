import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
///import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export const ListsCheckboxes = (params) => {
  const classes = useStyles();
  ///const [state, setState] = useState({});



//   useEffect(() => {
//     if (params.startups.length === 1) {
//         params.lists.map((list, index)=>{
//             if (list.startups.length && list.startups.indexOf(params.startups[0].id) > -1) {
//                 setState({ ...state, [list._id]: true })
//             } else {
//                 setState({ ...state, [list._id]: false })
//             }
//         })
//     }
    
// }, [])

  const renderCheckbox = (list) => {

    const listId = list._id
     return  <FormControlLabel
        control={<Checkbox checked={list.checked} onChange={params.onChange} name={listId} />}
        label={list.name}
        key={listId}
    />
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Выберите списки или добавьте новый</FormLabel>
        <FormGroup>
          {params.lists.map((list, index)=>{
           return (renderCheckbox(list))
          })
          }
        </FormGroup>
      </FormControl>
    </div>
  );
}
