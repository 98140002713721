
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    row_icon: {
      'min-width': '32px !important',
      "& .MuiButton-startIcon": {
        "margin-right": "0px !important",
        "margin-left": "0px !important",
      }
    },
  
  }));