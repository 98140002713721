import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {AuthContext} from '../context/AuthContext'
import { Link } from 'react-router-dom';
import {AppBar, Toolbar, Typography, Button, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));


export const Navbar = () => {
  const history = useHistory()
  const auth = useContext(AuthContext)

  const logoutHandler = event => {
    event.preventDefault()
    auth.logout()
    history.push('/')
  }

  const classes = useStyles();


  return (
    <AppBar position="static">
        <Toolbar>
            <Typography variant="h6" className={classes.title}>
            Techscout - база данных стартапов
            </Typography>
            <Button component={Link} to="/startups" color="inherit"> Стартапы
            </Button>
            <Button component={Link} to="/lists" color="inherit"> Списки
            </Button>
            {auth.userRole=='admin' && <Button component={Link} to="/userlogs" color="inherit"> Логи
            </Button>}
            <Button color="inherit" onClick={logoutHandler}>Выйти</Button>
        </Toolbar>
    </AppBar>
  )
}