import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {GridToolbarExportButton} from './toolbar/GridToolbarExportButton'
import {AuthContext} from '../context/AuthContext'
import {ListContext} from '../context/ListContext'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportList = (props) => {

    const {listId, listName} = useContext(ListContext);
    const {token, userRole} = useContext(AuthContext)
    const [data, setData] = useState({docs:[]}) 
    const {loading, request} = useHttp()

    const fetchStartups = useCallback(async () => {
        try {
          const serverData = await request('/api/startup', 'post', {listId:listId, isexport:1}, {
            Authorization: `Bearer ${token}`
          })
          makeExport(serverData)
        } catch (e) {}
    }, [listId])

    const handleOnClick = () => {
        fetchStartups()
    };

    const columns = userRole == 'admin' ? [
        { field: 'name', headerName: 'Название компании'},
        { field: 'link', headerName: 'Сайт компании'},
        { field: 'fio', headerName: 'ФИО заявителя'},
        { field: 'email', headerName: 'Е-mail заявителя'},
        { field: 'phone', headerName: 'Контактный телефон'},
        { field: 'social_link', headerName: 'Ссылка на соцсеть заявителя'},
        { field: 'country', headerName: 'Страна главного офиса'},
        { field: 'description_long', headerName: 'Подробное описание'},
        { field: 'scope_of_application', headerName: 'Сфера применения и поднаправление'},
        { field: 'source', headerName: 'Источник'},
    ] :
    [
        { field: 'name', headerName: 'Название компании'},
        { field: 'link', headerName: 'Сайт компании'},
        { field: 'country', headerName: 'Страна главного офиса'},
        { field: 'description_long', headerName: 'Подробное описание'},
        { field: 'source', headerName: 'Источник'},
    ]

    // useEffect(() => {
    //     makeExport()
    // }, [data])

    const makeExport = (serverData) => {
        //console.log('makeExport', serverData)
        if (!serverData.docs.length) {
            return;
        }
        const docs = serverData.docs
        const fileName = 'export';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const headers = columns.map((item) => {
            return item.headerName
        })
        const csvData = [];
        csvData.push(headers)
        docs.map((item) => {
            let row = []
            columns.forEach((column) => {
                row.push(item[column.field])
            })
            csvData.push(row)
        })

       /// console.log('csvData', csvData)

        const ws = XLSX.utils.aoa_to_sheet(csvData, {skipHeader:true});
        ws['!cols'] = [{wpx: 150}, {wpx: 50}, {wpx: 200}];
        // console.log(ws)
        // ws['A3'].l = { Target:"http://sheetjs.com", Tooltip:"Find us @ SheetJS.com!" };
        const Sheets = {}
        Sheets[listName] = ws
        const wb = { Sheets, SheetNames: [listName] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <GridToolbarExportButton onClick={handleOnClick} />
    )
}