import React from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import { StartupsPage } from './pages/StartupsPage'
import { ListsPage } from './pages/ListsPage'
import { ListPage } from './pages/ListPage'
import { UserLogsPage } from './pages/UserLogsPage'
import { AuthPage } from './pages/AuthPage'

export const useRoutes = isAuthenticated  => {
    if (isAuthenticated) {
        
        return (
            <Switch>
                <Route path="/startups" exact>
                    <StartupsPage/>
                </Route>
                <Route path="/lists" exact>
                    <ListsPage/>
                </Route>
                <Route path="/lists/:listId" exact>
                    <ListPage/>
                </Route>
                <Route path="/userlogs" exact>
                    <UserLogsPage/>
                </Route>
                <Redirect to='/startups' />
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/" exact>
                    <AuthPage />
                </Route>
                <Redirect to='/' />
        </Switch>
    )
}
