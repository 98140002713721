import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useAPI} from '../../hooks/useAPI'
import {AuthContext} from '../../context/AuthContext'
import {ListContext} from '../../context/ListContext'
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Button from '@material-ui/core/Button';
import {Prompt} from '../Prompt'


export const GridToolbarMultiDeleteStartup = (props) => {
    const [showPrompt, setShowPrompt] = React.useState(false)
    const [showDeleteFromListPrompt, setShowDeleteFromListPrompt] = React.useState(false)
    const {selectionModel = [], showDeleted} = props
    const deleteCount = selectionModel.length
    const {multiRestoreStartup, multiDeleteStartup, deleteStartupFromList} = useAPI()
    const {listId} = useContext(ListContext);

   
    const handleDeleteClick = async () => {
      setShowPrompt(true)
    };

    const handleDeleteFromListClick = async () => {
      setShowDeleteFromListPrompt(true)
    };

    

    const handleDelete = async () => {
      const response = await  multiDeleteStartup(selectionModel)
      if (response.status == 'ok') {
        props.refreshStartups()
      }
    };

    const handleDeleteFromList = async () => {
        const response = await deleteStartupFromList(listId, selectionModel)
        setShowPrompt(false)
        if (response.status == 'ok') {
            props.refreshStartups()
        }
    };

    const handleRestoreClick = async () => {
      const response = await  multiRestoreStartup(selectionModel)
      if (response.status == 'ok') {
        props.refreshStartups()
      }
    };

    const handleCancel = () => {
      setShowPrompt(false)
      setShowDeleteFromListPrompt(false)
    }

    return (
      <>
        {(deleteCount > 0 && !showDeleted) && <Button
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteClick}
        >
        Удалить
      </Button>}
      {(listId && deleteCount > 0 && !showDeleted) && <Button
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteFromListClick}
        >
        Удалить из списка
      </Button>}
      {(deleteCount > 0 && showDeleted) && <Button
            color="primary"
            startIcon={<RestoreFromTrashIcon />}
            onClick={handleRestoreClick}
        >
        Востановить
      </Button>}
      {showPrompt  && <Prompt
            onYes={handleDelete}
            onNo={handleCancel}
            text={`Удалить стартапы?`}
        />}
      {showDeleteFromListPrompt  && <Prompt
          onYes={handleDeleteFromList}
          onNo={handleCancel}
          text={`Удалить стартапы из списка?`}
      />}
      </>
      
    )
}