import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../../hooks/http.hook'
import {AuthContext} from '../../context/AuthContext'
import {ListContext} from '../../context/ListContext'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const GridToolbarShowDeleted = (props) => {
     const [showDeleted, setShowDeleted] = useState(props.showDeleted);

    const handleOnClick = () => {
      props.setShowDeleted(!showDeleted)
     // setShowDeleted(!showDeleted)
    };

    

    return (
      <>
        <FormControlLabel
        control={
          <Checkbox
            checked={showDeleted}
            onChange={handleOnClick}
            name="checkedB"
            color="primary"
          />
        }
        label="Показать удаленные"
      />
      </>
    )
}