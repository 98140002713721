import {GridCellExpand} from '../GridCellExpand'
import PropTypes from 'prop-types'

export const CellExpand = (props) => {
    return (
        <GridCellExpand
          value={props.value ? props.value.toString() : ''}
          width={props.colDef.width}
        />
        
      );
}

export const CellLink = (props) => {
    const link = props.value ? `<a href="${props.value.toString()}">${props.value.toString()}</a>`: ''
    return (
      <GridCellExpand
        value={link}
        width={props.colDef.width}
      />
      
    );
}

export const CellMail = (props) => {
    const email = props.value ? `<a href=mailto:${props.value.toString()}">${props.value.toString()}</a>`: ''
    return (
        <GridCellExpand
        value={email}
        width={props.colDef.width}
        />
        
    );
}

export const DateCell = (props) => {

    const value = props.value ? new Date(props.value).toISOString().replace(/T/, ' ').replace(/\..+/, '') : ''
    return (
      <GridCellExpand
        value={value}
        width={props.colDef.width}
      />
      
    );
  }

  CellExpand.propTypes = {
    colDef: PropTypes.any.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
  };