import React, {} from 'react'
import {UserLogsList} from '../components/UserLogsList'
import {Typography} from '@material-ui/core'

const riceFilterModel = {
    items: [{ columnField: 'name', operatorValue: 'contains', value: '' }],
};



export const UserLogsPage = () => { 
    console.log('UserLogsPage');
    return (
    <div style={{ height: '75vh', width: '100%' }}>
        <Typography variant="h3" gutterBottom paragraph>
         Пользовательские логи
        </Typography> 
        <UserLogsList />
           
    </div>
    );
}