import React, {useCallback, useContext, useEffect, useState} from 'react'
import { DataGrid , GridToolbarContainer, GridToolbarColumnsButton} from '@material-ui/data-grid'
import Icon from '@material-ui/core/Icon';
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {ListContext} from '../context/ListContext'
import PropTypes from 'prop-types'
import {GridCellExpand} from '../components/GridCellExpand'
import {ActionMenuStartup} from '../components/ActionMenuStartup'
import { StartupsDetail } from '../views/StartupsDetail'
import {Filter} from '../components/Filter'
import {ExportList} from './ExportList'
import {GridToolbarMultiDeleteStartup} from './toolbar/GridToolbarMultiDeleteStartup'
import {GridToolbarShowDeleted} from './toolbar/GridToolbarShowDeleted'
import {GridToolbarAddButton} from './toolbar/GridToolbarAddButton'
import {GridToolbarImportButton} from './toolbar/GridToolbarImportButton'
import {GridToolbarAddListButton} from './toolbar/GridToolbarAddListButton'
import {ImportDialog} from './ImportDialog'
import {DateCell} from './cells/CellExpand'

var globalFilter = {}

function highlightFilterResult(params){
  let cellValue = params.value ? params.value.toString() : ''
  if ( globalFilter.filterValues != null && (params.field in globalFilter.filterValues || 'all_fields' in globalFilter.filterValues)){
    let fieldValues = []

    if(params.field in globalFilter.filterValues ){
      fieldValues.push(globalFilter.filterValues[params.field])
    }

    if ('all_fields' in globalFilter.filterValues && params.field in globalFilter.filterValues['all_fields']) {
      const {all_fields} = globalFilter.filterValues
      if (Array.isArray(all_fields[params.field])){
        fieldValues.push(all_fields[params.field])
      }
    }
    

    cellValue = cellValue.replace(new RegExp(fieldValues.join("|"), "gi"), (match) => `<mark>${match}</mark>`);
  }

  return cellValue
}


function renderCellExpand(params) {
  let cellValue = highlightFilterResult(params)

  return (
    <GridCellExpand
      value={cellValue}
      width={params.colDef.width}
    />
    
  );
}

function renderLink(params) {
  let cellValue = highlightFilterResult(params)
  var links = params.value.toString().split(' ')
  var linkValue = ''
  for (var key in links) {
    let linkUrl = links[key]
    if(linkUrl == ''){
      continue
    }
    if(linkValue.length > 0){
      linkValue += " "
    }
    var prefix = ""
    if(!linkUrl.includes("http://") && !linkUrl.includes("https://")){
      prefix = "//"
    }
    linkValue += `<a href="${prefix}${linkUrl}">${linkUrl}</a>`
  }
  // var prefix = ""
  // if(!params.value.toString().includes("http://") && !params.value.toString().includes("https://")){
  //   prefix = "//"
  // }
  // const link = params.value ? `<a href="${prefix}${params.value.toString()}">${cellValue}</a>`: ''
  return (
    <GridCellExpand
      value={linkValue}
      width={params.colDef.width}
    />
    
  );
}

renderCellExpand.propTypes = {
  colDef: PropTypes.any.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export const StartupList = () => { 
    const {token, userRole} = useContext(AuthContext)
    const [data, setData] = useState({docs:[], totalDocs: 0, totalPages: 0}) 
    const [contacts, setContacts] = useState({id: ''}) 
    //const [page, setPage] = useState(0)
    //const [pageSize, setPageSize] = useState(20)
    const {loading, request} = useHttp()
    const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }])
    //const [filter, setFilter] = useState()
    const [tableParams, setTableParams] = useState({
      filter: null,
      page: 0,
      pageSize: 20,
    });
    const { filter, page,  pageSize} = tableParams;
    const [startupEdit, setStartupEdit] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);
    const [startupEditId, setStartupEditId] = useState(null);
    const [startupEditData, setStartupEditData] = useState(null);
    const {listId} = useContext(ListContext);
    const [startupImport, setStartupImport] = useState(false);
    
    const onSetShowDeleted = (sd) =>{
      setSelectionModel([])
      setShowDeleted(sd)
    }

    const CustomToolbar = () => {

      return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {(userRole === 'admin' || userRole === 'scout') && !showDeleted && <GridToolbarAddButton onClick={onCreateClick}/>}
            {(userRole === 'admin') &&  listId != undefined && !showDeleted && <ExportList />}
            {(userRole === 'admin' || userRole === 'scout') && <GridToolbarMultiDeleteStartup listId={listId} selectionModel={selectionModel} showDeleted={showDeleted} refreshStartups = {refreshStartups}/>}
            {!showDeleted &&<GridToolbarAddListButton selectionModel={selectionModel} showDeleted={showDeleted} refreshStartups = {refreshStartups}/>}
            
            {(userRole === 'admin' || userRole === 'scout') && !showDeleted && <GridToolbarImportButton setStartupImport={setStartupImport}/>}
            {(userRole === 'admin' || userRole === 'scout') && <GridToolbarShowDeleted setShowDeleted={onSetShowDeleted} showDeleted={showDeleted}/>}
        </GridToolbarContainer>
      );
       
    }

    const [selectionModel, setSelectionModel] = React.useState([]);

    const renderPhone = (params) =>{
      var cellValue = highlightFilterResult(params)
      return renderContactField(params, 'phone')
    }

    const renderSocialLink = (params) =>{
      var cellValue = highlightFilterResult(params)
      return renderContactField(params, 'social_link')
    }

    const renderMail = (params) =>{
      var cellValue = highlightFilterResult(params)
      return renderContactField(params, 'email')
    }

    const renderContactField = (params, fieldName) =>{
      var cellValue = highlightFilterResult(params)
      switch (fieldName) {
        case 'social_link':
          cellValue = params.value ? `<a href="${params.value.toString()}">${cellValue}</a>`: ''
          break;
        case 'email':
          cellValue = params.value ? `<a href=mailto:${params.value.toString()}">${cellValue}</a>`: ''
          break;
        default:
          break;
      }
      return (
          <GridCellExpand
          value={cellValue}
          width={params.colDef.width}
          />
      );
    }

    const RenderImportDialog = (props) => {
      if (props.show) {
        return <ImportDialog 
            setStartupImport={setStartupImport}
            refreshStartups={refreshStartups}
          />
      } else {
          return null;
      }
  }
    
    let columns = [
        { field: 'name', headerName: 'Название компании', width: 250 , renderCell: renderCellExpand},
        { field: 'link', headerName: 'Сайт компании', width: 250 , renderCell: renderLink},
        { field: 'fio', headerName: 'ФИО заявителя', width: 300, renderCell: renderCellExpand},
        { field: 'position', headerName: 'Должность заявителя', width: 200, renderCell: renderCellExpand},
        { field: 'email', headerName: 'Е-mail заявителя', width: 200, renderCell: renderMail},
        { field: 'phone', headerName: 'Контактный телефон', width: 200, renderCell: renderPhone},
        { field: 'social_link', headerName: 'Ссылка на соцсеть заявителя', width: 300, renderCell: renderSocialLink},
        { field: 'founders', headerName: 'Основатели', width: 300, renderCell: renderCellExpand},
        { field: 'country', headerName: 'Страна главного офиса', width: 200, renderCell: renderCellExpand},
        { field: 'description_long', headerName: 'Подробное описание', width: 600, renderCell: renderCellExpand},
        { field: 'scope_of_application', headerName: 'Сфера применения и поднаправление', width: 400, class: 'test', renderCell: renderCellExpand},
        { field: 'readiness_stage', headerName: 'Стадия развития компании', width: 400, renderCell: renderCellExpand},
        { field: 'source', headerName: 'Источник', width: 300, renderCell: renderCellExpand},
        { 
            field: 'actions',
            headerName: 'Действия',
            width: 150,
            sortable: false,
            renderCell: (params) => (
             <ActionMenuStartup
                 startup = {params.row}
                 userRole = {userRole}
                 onEditClick = {(startupId) => onEditClick(startupId)}
                 refreshStartups = {refreshStartups}
                 isDeleted = {showDeleted}
                //  listId ={listId}
             />
            ),
        },
    ];

    if(showDeleted){
      columns = [{ field: 'datedeleted', headerName: 'Дата удаления', width: 170 , type: 'dateTime', renderCell: DateCell}].concat(columns)
    }

    const filterFields = [
      { field: 'name', label: 'Название компании'},
      { field: 'link', label: 'Сайт компании'},
      { field: 'fio', label: 'ФИО заявителя'},
      { field: 'email', label: 'Е-mail заявителя'},
      { field: 'phone', label: 'Контактный телефон'},
      { field: 'social_link', label: 'Ссылка на соцсеть заявителя'},
      { field: 'country', label: 'Страна главного офиса'},
      { field: 'description_long', label: 'Подробное описание'},
      { field: 'scope_of_application', label: 'Сфера применения и поднаправление'},
      { field: 'readiness_stage', label: 'Стадия развития компании'},
      { field: 'source', label: 'Источник'},
  ];

  

    const onEditClick = (startupId) => {
      setStartupEditId(startupId)
      setStartupEdit(true)
    }


    const onCreateClick = (startupId) => {
      setStartupEdit(true)
      setStartupEditData({})
    }

    const handleCloseEdit = () => {
        setStartupEditData(null)
        setStartupEditId(null)
        setStartupEdit(false)
        fetchStartups()
    };

    const handlePageChange = (params) => {
        //setPage(params.page)
        setTableParams({...tableParams, page: params.page})
        setSelectionModel([])
    }; 

    const handleSortModelChange = (params) => {
        if (params.sortModel !== sortModel) {
          setSortModel(params.sortModel)
        }
    };

    const handlePageSizeChange = (params) => {
      //setPageSize(params.pageSize);
      setTableParams({...tableParams, pageSize: params.pageSize})
    };

    const onFilterChange = useCallback((params) => {
        globalFilter =  params.filter
        //setFilter(params.filter)
        setTableParams({...tableParams, filter:params.filter, page:0})
      }, [tableParams])

    const fetchStartups = useCallback(async () => {
        try {
          const serverData = await request('/api/startup', 'post', {page, pageSize, sortModel, filter, listId, showDeleted}, {
            Authorization: `Bearer ${token}`
          })


          setData(serverData)
        } catch (e) {}
    }, [token, request, page, pageSize, filter, sortModel, listId, showDeleted])


  const getContacts = useCallback(async (params) => {
    try {
      const serverData = await request(`/api/startup/contacts/${params.id}`, 'post', {field:params.field}, {
        Authorization: `Bearer ${token}`
      })
      setContacts({email: '', phone: '',  social_link: '', field:params.field, ...serverData})
    } catch (e) {
    }
  }, [token, request])

    const refreshStartups = () => {
      fetchStartups()
    }


    useEffect(() => {
        fetchStartups()
    }, [fetchStartups, page, pageSize, sortModel, filter, showDeleted])

    const handleCellClick = (params)  => {
      // if (userRole !== 'admin' && (params.field == 'phone' || params.field == 'email' || params.field == 'social_link' )){
      //   getContacts(params)
      // }
      if(!showDeleted && (userRole === 'admin' || userRole === 'scout')){
        onEditClick(params.id)
      }
    }

    const fetchStartup = useCallback(async () => {
        try {
            const startupData = await request(`/api/startup/${startupEditId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setStartupEditData(startupData)
        } catch (e) {}
    }, [token, request, startupEditId])

    useEffect(() => {
        fetchStartup()
    }, [fetchStartup, startupEditId])
    
    return (
    <>
        <Filter 
          fields = {filterFields}
          onFilterChange = {onFilterChange}/>
        <DataGrid 
            className = "page_grid"
            rows={data.docs}
            columns={columns}
            pageSize={pageSize}
            stickyHeader
            rowHeight={30}
            disableColumnMenu={true}
            disableSelectionOnClick = {true}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[20, 50, 100]}
            rowCount= {data.totalDocs}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loading={loading}
            initialState={{
              pinnedColumns: { right: ['actions'] },
            }}
            components={{
                Toolbar: CustomToolbar,
            }}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel.selectionModel);
            }}
            selectionModel={selectionModel}
            onCellDoubleClick = {handleCellClick}
            localeText={{
                toolbarColumns: 'Поля',
                columnsPanelTextFieldLabel: 'Поиск поля',
                columnsPanelTextFieldPlaceholder: 'Название поля',
                columnsPanelShowAllButton: 'Показать все',
                columnsPanelHideAllButton: 'скрыть все',
                toolbarFilters: 'Фильтр',
                filterPanelOperators: 'Оператор',
                filterPanelColumns: 'Поле',
                filterPanelInputLabel: 'Значение',
                filterPanelInputPlaceholder: 'Введите значение',
                filterOperatorContains: 'Содержит',
                filterOperatorEquals: 'Равно',
                filterOperatorStartsWith: 'Начинается с',
                filterOperatorEndsWith: 'Заканчивается на',
              }}
        />

        {startupEditData && <StartupsDetail
            open={startupEdit}
            onClose={handleCloseEdit}
            startupData={startupEditData}
        />}
        {startupImport && <RenderImportDialog
            show={startupImport}
        />}
    </>
    );
}