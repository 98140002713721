import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {ListContext} from '../context/ListContext'
import {StartupList} from '../components/StartupsList'
import {Typography} from '@material-ui/core'
import {useParams} from 'react-router-dom'
import {Loader} from '../components/Loader'

export const ListPage = () => { 

    const {token} = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [listData, setListData] = useState(null);

    const { listId } = useParams();
    

    const getList = useCallback(async () => {
        try {
            const listData = await request(`/api/list/${listId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setListData(listData)
        } catch (e) {}
    }, [token, listId, request])

    useEffect(() => {
        getList()
    },[getList])

    if (loading || !listData) {
        return <Loader />
    } else {
        return (
        <ListContext.Provider value={{listId:listId, listName:listData.name}}>
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h3" gutterBottom paragraph>
            Стартапы списка {listData.name}
            </Typography>
            <StartupList isList/>
        </div>
        </ListContext.Provider>
        )
    }
}