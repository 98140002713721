import React, {useContext, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { AddToList } from "../AddToList";
import PublishIcon from '@material-ui/icons/Publish';
import {ListContext} from '../../context/ListContext'

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

export const GridToolbarAddListButton = (props) => {
    const classes = useStyles();
    const {selectionModel = [], showDeleted, refreshStartups} = props
    const startupsCount = selectionModel.length
    const {listId} = useContext(ListContext);
    const [showAddToListDialog, setShowAddToListDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = async () => {
        setShowAddToListDialog(true)
    };

    const cleanUpAaddToList = () => {
        setShowAddToListDialog(false)
      }

    const RenderAddToListDialog = (props) => {
        if (props.show) {
          return <AddToList 
              startups={selectionModel}
              cleanUpHandle={cleanUpAaddToList}
              onSave={props.onSave}
            />
        } else {
            return null;
        }
    }

    return (
       <>
      {(startupsCount > 0) && !listId && <Button
        color="primary"
        startIcon={<PlaylistAddIcon />}
        onClick={handleClick}
            >
            Добавить в список
        </Button>}
        <RenderAddToListDialog show={showAddToListDialog} onSave={props.onSave}/>
        </>
    )
}
  
  