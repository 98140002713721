import React, {useState, useEffect, useContext} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useAPI} from '../hooks/useAPI'
import axios from "axios";
import {useMessage} from '../hooks/message.hook'

// const uploadToServer = (formData, onUploadProgress) => {
//     return axios.post(`/api/startup/cvsimport`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       onUploadProgress,
//     });
//   };


export const ImportDialog = (props) => { 
  const [open, setOpen] = useState(true);
  const [progress, setProgress] = useState(0);
  const setStartupImport = props.setStartupImport
  const [formData, setFormData] = useState()
  const {importStarups} = useAPI()
  const {message} = useMessage()
  
  const handleClose = () => {
    setOpen(false);
    setStartupImport(false)
    // params.cleanUpHandle();
  };

  const handleSelect = (event) => {
   // setOpen(false);
    //setStartupImport(false)
    // params.cleanUpHandle();
    const tmpFormdata = new FormData();
    const file = event.target.files[0];
    tmpFormdata.append('file', file);
    setFormData(tmpFormdata)
  };

  const handleImport = async (event) => {
    // setOpen(false);
     //setStartupImport(false)
     // params.cleanUpHandle();
     if(formData != null){
        // const response = await importStarups(formData)
        // setStartupImport(false)
        // if (response.status == 'ok') {
        //     props.refreshStartups()
        // }
        importStarups(formData)
        .then(async (response) => {
            setStartupImport(false)
            message.success()
            props.refreshStartups()
          })
        .catch(() => {
            setStartupImport(false)
        });

     }
   };


  if (open) {
    return (
        <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Импорт стартапов</DialogTitle>
            <DialogContent>
                <TextField 
                type="file"
                inputProps={{accept:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}}//".csv, text/csv, application/csv"}}
                onChange={handleSelect}
                />
                {/* <TextField
                    variant="standard"          
                    type="text"
                    InputProps={{
                        endAdornment: (
                        <IconButton component="label">
                            <AddIcon/>
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleImport}
                            name="[licenseFile]"
                            />
                        </IconButton>
                        ),
                    }}
                    /> */}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Отменить
            </Button>
            <Button onClick={handleImport} color="primary">
                Импортировать
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
  } else {
      return null;
  }
}