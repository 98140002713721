import React, {useCallback, useContext, useEffect, useState} from 'react'
import { DeleteList} from '../components/DeleteList'
import PropTypes from 'prop-types';
//import clsx from 'clsx';
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import {TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel} from '@material-ui/core';


// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import TablePagination from '@material-ui/core/TablePagination';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import {Typography} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import Paper from '@material-ui/core/Paper';
import {ListDetail} from '../components/ListDetail'
import {Link} from 'react-router-dom'
import {useMessage} from '../hooks/message.hook';

///import FormControlLabel from '@material-ui/core/FormControlLabel';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

function descendingComparator(a, b, orderBy) {
    let compA = orderBy == 'startupscount' ? a.startups.length : a[orderBy]
    let compB = orderBy == 'startupscount' ? b.startups.length : b[orderBy]
    if (compB < compA) {
      return -1;
    }
    if (compB > compA) {
      return 1;
    }
    return 0;
  }

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

  const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Название списка' },
    { id: 'startupscount', numeric: true, disablePadding: false, label: 'Кол-во записей' },
    { id: 'edit', numeric: false, disablePadding: false, label: '', sortable: false },
  ];

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.headerTitle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.sortable ? createSortHandler(headCell.id) : null}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
  
    return (
      <Toolbar
        className={classes.root}
      >
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Списки
        </Typography>
      </Toolbar>
    );
  };
  
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    headerTitle: {
        'font-weight':600
    }
  }));

export const ListsPage = () => {
    const {token} = useContext(AuthContext)
    const [data, setData] = useState({docs:[], limit: 10, totalDocs: 0, totalPages: 0})
    const {loading, request} = useHttp()
    const {message} = useMessage()
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [listEditId, setListEditId] = useState(null);
    ///const [listEditData, setListEditData] = useState(null);
    const [listEdit, setListEdit] = useState(false);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.docs.length - page * rowsPerPage);

    const fetchLists = async () => {
        try {
          const serverData = await request('/api/list', 'post', {}, {
            Authorization: `Bearer ${token}`
          })
          setData(serverData)
        } catch (e) {}
    }

    useEffect(() => {
      fetchLists()
      ///message.promise(fetchLists(), 'Загружаются данные')
    }, [])

    const onEditClick = (listId) => {
        //console.log(listId)
        setListEditId(listId)
        setListEdit(true)
    }
    const handleCloseListEdit = () => {
        setListEditId(null)
        setListEdit(false)
    }

    const handleSaveList = () => {
      fetchLists()
    }

    const onListDeleted = () => {
      fetchLists()
    }

    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='medium'
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data.docs.length}
                />
                <TableBody>
                  {stableSort(data.docs, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
    
                      return (
                        <TableRow
                          hover
                          key={row.name+'_row'}
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="default">
                            <Link to={`/lists/${row.id}`}>{row.name}</Link>
                          </TableCell>
                          <TableCell align="right">{row.startups.length}</TableCell>
                          <TableCell align="right">
                            <CreateIcon
                                color="primary"
                                style={{ marginLeft: 16, cursor:'pointer' }}
                                onClick={() => onEditClick(row.id)}
                                >
                            </CreateIcon>
                          </TableCell>
                          <TableCell align="right">
                            <DeleteList onListDeleted={onListDeleted} listId={row.id}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.docs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
          {listEdit && <ListDetail
            onClose={handleCloseListEdit}
            onSave={handleSaveList}
            listId={listEditId}
        />}
        </div>
      );

}