import React, {useState, useContext} from 'react';
import { useStyles } from './Styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AddToList } from "./AddToList";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Tooltip from '@material-ui/core/Tooltip';
import {DeleteStartup} from './DeleteStartup';
import {RestoreStartup} from './RestoreStartup';
import {ListContext} from '../context/ListContext'


export const ActionMenuStartup = (props) =>  {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddToListDialog, setShowAddToListDialog] = useState(false);
  const {userRole, startup, refreshStartups, isDeleted} = props
  const {listId} = useContext(ListContext);
  const id = props.startup.id;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowAddToListDialog(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
    
  };

  const handleAddToListDialog = () => {
    handleClose();
    setShowAddToListDialog(true)
  }

//   useEffect(() => {
//     console.log('useEffect ActionMenuStartUp')
//     return function cleanup() {
//       setShowAddToListDialog(false)
//       console.log('useEffect cleanup ActionMenuStartUp')
//     }
    
// })

  const handleEdit = (event) => {
    ///console.log('handleClick', id);
    props.onEditClick(id)
  };

  const handleDelete = (event) => {
    ///console.log('handleClick', id);
    setAnchorEl(event.currentTarget);
    setShowAddToListDialog(false)
  };

  const cleanUpAaddToList = () => {
    setShowAddToListDialog(false)
  }

  const RenderAddToListDialog = (props) => {
    if (props.show) {
      return <AddToList 
          startups={[id]}
          cleanUpHandle={cleanUpAaddToList}
          onSave={props.onSave}
        />
    } else {
        return null;
    }
}


const AddToListButton = () => {
  return (<Tooltip title="Добавить в список">
      <Button className={classes.row_icon} aria-controls="simple-menu" aria-haspopup="true" startIcon={<PlaylistAddIcon />} onClick={handleAddToListDialog}>
      </Button>
      </Tooltip>)
}


const EditButton = () => {
    return (<Button className={classes.row_icon} aria-controls="simple-menu" aria-haspopup="true" startIcon={<EditIcon />} onClick={handleEdit}>
    </Button>)
}

return (
  <div>
    {(userRole == 'admin' || userRole == 'scout') && !startup.deleted && <EditButton/>}
    {!startup.deleted && <AddToListButton/>}
    <DeleteStartup userRole={userRole} startup={startup} refreshStartups= {refreshStartups}/>
    <RestoreStartup userRole={userRole} startup={startup} refreshStartups= {refreshStartups}/>
    
    {/* <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleAddToListDialog}>Добавить в список</MenuItem>
    </Menu> */}
    <RenderAddToListDialog show={showAddToListDialog} onSave={props.onSave}/>
  </div>
  );
}


