import React, {useState, useEffect, useContext} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {useMessage} from '../hooks/message.hook'
import {ListsCheckboxes} from './ListsCheckboxes'

export const AddToList = (params) => { 
  const [open, setOpen] = useState(true);
  const [newList, setNewList] = useState(null);
  const [lists, setLists] = useState([]);
  const [msgText, setMsgText] = useState(null);
  const {token} = useContext(AuthContext)
  const {request, error, clearError} = useHttp()
  const {message} = useMessage()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    params.cleanUpHandle();
  };

  const handleListNameChange = (e) => {
    setNewList(e.target.value)
   // console.log(e.target.value)
  }

//   const handleListCheckboxChange = (event) => {
//     // if (params.startups.length === 1) {
//     //     handleListCheckboxChangeSingle(event)
//     // } else {
//     //    // handleListCheckboxChangeMultiple(event)
//     // }
//   };
  const handleListCheckboxChange = (event) => {
    const startupId = params.startups[0];
    //Идем по списку всех Списков
    const updatedLists = lists.map((list, index)=>{
        // Если нашли список, на котором кликнули. 
        // Ппц, даже для этого надо по массиву проходить.. Может, все на объекты переделать и брать по ключу?
        // Или переделать на For
        if (list.id == event.target.name) {
            list.changed = true

            
            // Если поставили галочку, добавляем стартап в список
            if (event.target.checked) {
                list.checked = true;
                list.startups.push(...params.startups)
            // если убрали галочку, удаляем стартап из списка        
            } else {
                list.checked = false
                const newListOfStartups = list.startups.filter((sturtup) => {
                    return params.startups.indexOf(sturtup) < 0;
                })

                list.startups = [...newListOfStartups]
            }
        } 

        list.startups = list.startups.filter((value, index, array) => array.indexOf(value) === index);
        return list;
    })
    setLists(updatedLists)
  };

//   const handleListCheckboxChangeMultiple = (event) => {
//     console.log('handleListCheckboxChangeMultiple')
//   }

    const fetchLists = async (cleanupFunction) => {
        
        try {
            const serverData = await request('/api/list', 'post', {}, {
                Authorization: `Bearer ${token}`
            })
            const serverLists = serverData.docs
            if (params.startups.length === 1) {
                serverLists.map((list, index)=>{

                    if (list.startups.length && list.startups.indexOf(params.startups[0]) > -1) {
                        list.checked = true
                    } else {
                        list.checked = false
                    }
                })
            }
            if (!cleanupFunction) {
                setLists(serverLists)
            }

        } catch (e) {}
    }

    useEffect(() => {
        console.log('useEffect')
        let cleanupFunction = false;
        fetchLists(cleanupFunction)
        return () => cleanupFunction = true;
    }, [])

  const handleSave = async () => {
    try {
        const toastId = message.loading()
        if (newList) {
            
            ///setMsgText(newList)
            const data = await request('/api/list/add', 'post', {name: newList, startups:params.startups}, {
                Authorization: `Bearer ${token}`
            })
        }
        
        const listsToSend = lists.filter((list, index) => {
            return list.changed === true
        })
        
        if (listsToSend.length) {
            const data = await request('/api/list/additems', 'post', {lists: listsToSend}, {
                Authorization: `Bearer ${token}`
            })
        }
        message.dismiss(toastId)
        message.success()
     // history.push(`/detail/${data.link._id}`)
    } catch (e) {
        message.error(e.message || 'Ошибка')
    }
    handleClose()
    if (params.onSave) {
        params.onSave()
    }
  }

  ///console.log('open', open);
  if (open) {
    return (
        <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Списки</DialogTitle>
            <DialogContent>
                <ListsCheckboxes 
                    startups={params.startups}
                    onChange={handleListCheckboxChange}
                    lists={lists}
                />
            <TextField
                autoFocus
                margin="dense"
                id="list_name"
                label="Новый список"
                type="text"
                fullWidth
                inputProps = {
                    {onChange: handleListNameChange}
                }
                
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Отменить
            </Button>
            <Button onClick={handleSave} color="primary">
                Сохранить
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    )
  } else {
      return null;
  }
}