import React from 'react'

import DeleteIcon from '@material-ui/icons/Delete';
import {Prompt} from './Prompt'
import {useAPI} from '../hooks/useAPI'

const ConfirmDeletion = (params) => {

}

export const DeleteList = (props) => {
    const [showPrompt, setShowPrompt] = React.useState(false)
    const [runParentEvent, setrRunParentEvent] = React.useState(false)
    const {deleteList} = useAPI()

    const handleOnClick = () => {
        setShowPrompt(true)
        console.log('handleOnClick', props.listId)
    }

    const handleOk = async () => {
        const response = await deleteList(props.listId)
        setShowPrompt(false)
        if (response.status == 'ok') {
            props.onListDeleted()
        }
        
       
    }

    const handleCancel = () => {
        setShowPrompt(false)
    }



    return(
        <>
        <DeleteIcon
            color="error"
            style={{ marginLeft: 16, cursor:'pointer' }}
            onClick={handleOnClick}
            >
        </DeleteIcon>
        {showPrompt && <Prompt
            onYes={handleOk}
            onNo={handleCancel}
            text='Удалить список?'
        />}
        </>
    )
}