import React from 'react'

import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import {Prompt} from './Prompt'
import {useAPI} from '../hooks/useAPI'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useStyles } from './Styles';

export const RestoreStartup = (props) => {
    const classes = useStyles();
    const [showPrompt, setShowPrompt] = React.useState(false)
    const [runParentEvent, setrRunParentEvent] = React.useState(false)
    const {restoreStartup} = useAPI()
    const {userRole, startup} = props

    const handleOnClick = () => {
        setShowPrompt(true)
    }

    const handleOk = async () => {
        const response = await restoreStartup(startup.id)
        setShowPrompt(false)
        if (response.status == 'ok') {
            props.refreshStartups()
        }
        
       
    }

    const handleCancel = () => {
        setShowPrompt(false)
    }



    return(
        <>
        {((userRole == 'admin' || userRole == 'scout') && startup.deleted)&& <Tooltip title="Востановить стартап">
            <Button className={classes.row_icon} aria-controls="simple-menu" aria-haspopup="true" startIcon={<RestoreFromTrashIcon />} onClick={handleOnClick}>
            </Button>
        </Tooltip>}
        {showPrompt && <Prompt
            onYes={handleOk}
            onNo={handleCancel}
            text={`Удалить стартап - ${startup.name}?`}
        />}
        </>
    )
}