import 'materialize-css'
import React from 'react';
import { useRoutes } from './routes';
import {useAuth} from './hooks/auth.hook'
import { BrowserRouter as Router } from 'react-router-dom';
import {AuthContext} from './context/AuthContext'
import {Navbar} from './components/Navbar'
import {Loader} from './components/Loader'
import { Container } from '@material-ui/core';
import { Toast} from './components/Toast'
///import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {  unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ruRU } from '@material-ui/core/locale'



function App() {
  const {token, login, logout, userId, ready, userRole} = useAuth()
  const isAuthenticated = !!token
  const routes = useRoutes(isAuthenticated)

  const theme = createMuiTheme({
  
  }, ruRU);

  if (!ready) {
    return <Loader />
  }
  
  return (
    <AuthContext.Provider value={{
      token, login, logout, userId, userRole, isAuthenticated
    }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Container >
            <Toast />
          { isAuthenticated && <Navbar /> }
            {/* <div className="container"> */}
              {routes}
            {/* </div> */}
          </Container>
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
