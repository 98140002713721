import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

export const GridToolbarExportButton = (props) => {
    const classes = useStyles();


    return (
        <Button
            color="primary"
            startIcon={<ArrowDownwardIcon />}
            onClick={props.onClick}
        >
        Экспорт
      </Button>
    )
}
  
  