import React , {useContext, useEffect, useState}from 'react'
import {makeStyles, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'

function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    };
  }
  
const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 800,
      height: 600,
      overflowY: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    },
    buttons_block: {
        '& > *': {
          margin: theme.spacing(1),
        },
    },
}));


export const StartupsDetail = (props) => {
    const { open, onClose, startupData} = props
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle)
    const [startup, setStartup] = useState(null)
    const {request} = useHttp()
    const {token} = useContext(AuthContext)
    
    

    useEffect(() => {
        setStartup(startupData)
    }, [ startupData])

    const changeHandler = event => {
        setStartup({ ...startup, [event.target.name]: event.target.value })
    }


    const clickSave = async () => {
          try {
            await request('/api/startup/save', 'post', {startup}, {
              Authorization: `Bearer ${token}`
            })
            onClose()
           // history.push(`/detail/${data.link._id}`)
          } catch (e) {}
    }


    const text_field = (fieldName, label, multiline = false, rows = 1) => {
        return (<TextField
            id={fieldName}
            name={fieldName}
            label={label}
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            variant="outlined"
            value={startup? startup[fieldName]: ''}
            onChange={(event) => changeHandler(event)}
            multiline = {multiline}
            rows = {rows}
        />)
        }



    const body = (
        <div style={modalStyle} className={classes.paper}>
            {startup != null && startup['id'] != null &&<h2 id="simple-modal-title"> Данные стартапа</h2>}
            {startup != null && startup['id'] == null &&<h2 id="simple-modal-title"> Заполните данные нового стартапа</h2>}
            {text_field("name", "Название")}
            {text_field("link", "Сайт компании")}
            {text_field("fio", "ФИО заявителя")}
            {text_field("position", "Должность заявителя")}
            {text_field("email", "Е-mail заявителя")}
            {text_field("phone", "Контактный телефон")}
            {text_field("social_link", "Ссылка на соцсеть заявителя")}
            {text_field("founders", "Основатели",  true, 4)}
            {text_field("country", "Страна главного офиса")}
            {text_field("description_long", "Подробное описание", true, 10)}
            {text_field("scope_of_application", "Сфера применения и поднаправление", true, 4)}

            
            <div className={classes.buttons_block}>
                <Button variant="contained" color="primary" onClick={clickSave}>
                         {(startup&&startup['id'] != null)? 'Сохранить': 'Создать'}
                </Button>
                <Button variant="contained" color="secondary" onClick={onClose}>
                    Отмена
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            //disableBackdropClick
        >   
            { body }
        </Modal>
    )
}

StartupsDetail.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    startupData: PropTypes.object,
};