import {toast} from 'react-hot-toast';


export const useMessage = () => {
 const message = (msg) => toast(msg) 
 message.success = (msg) => toast.success(msg || 'Успешно') 
 message.error = (msg) => toast.error(msg || 'Ошибка') 
 message.custom = (msg) => toast.custom(msg) 
 message.loading = (msg) => toast.loading(msg || 'Запрос выполняется') 
 message.promise = (promise, loadingMsg, successMsg, errorMsg) => toast.promise(promise, {
                                                                              loading: loadingMsg || 'Загрузка', // Не работает
                                                                              success: successMsg || 'Успешно',
                                                                              error: errorMsg || 'Ошибка',
                                                                            }) 
message.dismiss = (toastId) => toast.dismiss(toastId)                                                                    

 return {message}
}
