import React , {useContext, useEffect, useState}from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from '@material-ui/core';

export const Prompt = (props) => {
    
    const onNo = () => {
        props.onNo()
    }

    const onYes = () => {
        props.onYes()
    }

    return (
        <Dialog open={true} onClose={onNo} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title || 'Подтвердите действие'}</DialogTitle>
            <DialogContent>
                {props.text || 'Вы уверены, что хотите выполнить действие?'}
            </DialogContent>
            <DialogActions>
            <Button onClick={onNo} color="primary">
                Отменить
            </Button>
            <Button onClick={onYes} color="secondary">
                Подтвердить
            </Button>
            </DialogActions>
        </Dialog>
    )
}