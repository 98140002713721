import React from 'react'
import {Typography} from '@material-ui/core'
import {StartupList} from '../components/StartupsList'

export const StartupsPage = () => { 
    
    return (
    <div style={{ height: '75vh', width: '100%' }}>
        <Typography variant="h3" gutterBottom paragraph>
         Список стартапов
        </Typography> 
        <StartupList/>
    </div>
    );
}