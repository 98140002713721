import {useState, useCallback, useEffect} from 'react'
import jwt_decode from "jwt-decode";

const storageName = 'userData'

export const useAuth = () => {
  const [token, setToken] = useState(null)
  const [ready, setReady] = useState(false)
  const [userId, setUserId] = useState(null)
  const [userRole, setUserRole] = useState(null)

  const login = useCallback((jwtToken, id) => {
    setToken(jwtToken)
    
    var decoded = jwt_decode(jwtToken);
    setUserId(decoded.userId)
    setUserRole(decoded.userRole || 'scout')
    // console.log('jwtToken', decoded)
    localStorage.setItem(storageName, JSON.stringify({
      token: jwtToken
    }))
  }, [])


  const logout = useCallback(() => {
    setToken(null)
    setUserId(null)
    localStorage.removeItem(storageName)
  }, [])

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName))

    if (data && data.token) {
      login(data.token, null)
    }
    setReady(true)
  }, [login])


  return { login, logout, token, userId, ready, userRole }
}