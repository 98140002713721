import React , {useContext, useEffect, useState}from 'react'
import {makeStyles, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {useHttp} from '../hooks/http.hook'
import {useMessage} from '../hooks/message.hook'
import {AuthContext} from '../context/AuthContext'


export const ListDetail = (props) => {
    const [listEditData, setListEditData] = useState(null);
    const {token} = useContext(AuthContext)
    const {loading, request} = useHttp()
    const listEditId = props.listId
    const [fieldsValidation, setFieldsValidation] = useState({listName:{isError:false, errorText:''}})
    const {message} = useMessage()

    const fetchList = async () => {
        if (!listEditId) return
        try {
            const ListData = await request(`/api/list/${listEditId}`, 'GET', null, {
                Authorization: `Bearer ${token}`
            })
            setListEditData(ListData)
        } catch (e) {}
    }

    useEffect(() => {
        fetchList()
    }, [])

    const handleSave = async () => {
        if (fieldsValidation.listName.isError) {
            return;
        }
        try {
            const toastId = message.loading()
            const data = await request('/api/list/save', 'post', {name: listEditData.name, id:listEditData.id}, {
                Authorization: `Bearer ${token}`
            })
            message.dismiss(toastId)
            message.success()
        } catch (e) {
            message.error(e.message || 'Ошибка')
        }
        props.onClose()
        props.onSave()
    }

    const handleListNameChange = (e) => {
        if (!e.target.value) {
            setFieldsValidation({...fieldsValidation, listName:{isError:true, errorText:'Введите название'}})
        } else {
            setFieldsValidation({...fieldsValidation, listName:{isError:false, errorText:''}})
        }

        setListEditData({...listEditData, name:e.target.value})
    }

    return (
        <Dialog open={listEditData!==null} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Название списка</DialogTitle>
            <DialogContent>
            <TextField
                autoFocus
                defaultValue={listEditData?listEditData.name:''}
                margin="dense"
                id="list_name"
                label="Название списка"
                type="text"
                fullWidth
                required={true}
                helperText={fieldsValidation.listName.errorText}
                error={fieldsValidation.listName.isError}
                inputProps = {
                    {onChange: handleListNameChange}
                }
                
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={props.onClose} color="primary">
                Отменить
            </Button>
            <Button onClick={handleSave} color="primary">
                Сохранить
            </Button>
            </DialogActions>
        </Dialog>
    )
}