export const ruRu = {
    toolbarColumns: 'Поля',
    columnsPanelTextFieldLabel: 'Поиск поля',
    columnsPanelTextFieldPlaceholder: 'Название поля',
    columnsPanelShowAllButton: 'Показать все',
    columnsPanelHideAllButton: 'скрыть все',
    toolbarFilters: 'Фильтр',
    filterPanelOperators: 'Оператор',
    filterPanelColumns: 'Поле',
    filterPanelInputLabel: 'Значение',
    filterPanelInputPlaceholder: 'Введите значение',
    filterOperatorContains: 'Содержит',
    filterOperatorEquals: 'Равно',
    filterOperatorStartsWith: 'Начинается с',
    filterOperatorEndsWith: 'Заканчивается на',
}