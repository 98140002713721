import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

export const GridToolbarImportButton = (props) => {
    const classes = useStyles();

    const handleClick = async () => {
        props.setStartupImport(true)
    };

    return (
        <Button
            color="primary"
            startIcon={<PublishIcon />}
            onClick={handleClick}
        >
        Импорт стартапов из XLSX
      </Button>
    )
}
  
  