import React, {useCallback, useContext, useEffect, useState} from 'react'
import { DataGrid , GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, ruRU} from '@material-ui/data-grid'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {ruRu as customRuRu} from '../localization/dataGrid'
import {DateCell, CellExpand} from './cells/CellExpand'

   
  function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton label="dasdas"/>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      );
    }
  
    
  export const UserLogsList = () => { 
      const {token} = useContext(AuthContext)
      const [data, setData] = useState({docs:[], totalDocs: 0, totalPages: 0}) 
      const [page, setPage] = useState(0)
      const [pageSize, setPageSize] = useState(10)
      const {loading, request} = useHttp()
      ///const [sortModel, setSortModel] = useState([{ field: 'list', sort: 'asc' }])
      const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'desc' }])
      const [filterValue, setFilterValue] = useState()
      ///console.log('UserLogsList');
      const columns = [
          { field: 'createdAt', headerName: 'Log Time', width: 170 , type: 'dateTime', renderCell: DateCell},
          { field: 'user.email', valueGetter: (params) => params.row.user ? params.row.user.email : params.value, headerName: 'User', width: 200 , renderCell: CellExpand},
          { field: 'list.name', valueGetter: (params) => params.row.list ? params.row.list.name : params.value, headerName: 'List', width: 200 , renderCell: CellExpand},
          { field: 'startup.name', valueGetter: (params) => params.row.startup ? params.row.startup.name : params.value, headerName: 'Startup', width: 200 , renderCell: CellExpand},
          { field: 'message', headerName: 'Текст',flex: 1, renderCell: CellExpand},
      ];
  
  
      const handlePageChange = (params) => {
          setPage(params.page)
      }; 
  
      const handleSortModelChange = (params) => {
          if (params.sortModel !== sortModel) {
            setSortModel(params.sortModel)
          }
      };
  
      const handlePageSizeChange = (params) => {
        setPageSize(params.pageSize);
      };
  
      const onFilterChange = useCallback((params) => {
          ///console.log(params)
          var type = null;
          if (params.filterModel.items[0]) {
              var field = params.filterModel.items[0].columnField
              params.columns.map((column) => {
                  if (column.field == field) {
                    type = column.type
                  }
              })
              params.filterModel.items[0].type = type
          }
          
          setFilterValue(params.filterModel.items[0])
        }, [])
  
      const fetchUserLogs = useCallback(async () => {
          try {
            const serverData = await request('/api/userlogs', 'post', {page, pageSize, sortModel, filterValue}, {
              Authorization: `Bearer ${token}`
            })
            setData(serverData)
          } catch (e) {}
      }, [token, request, page, pageSize, sortModel, filterValue])
  
      useEffect(() => {
        fetchUserLogs()
      }, [fetchUserLogs, page, pageSize, sortModel, filterValue])
  
        
      return (
          <DataGrid 
              className = "page_grid"
              rows={data.docs}
              columns={columns}
              pageSize={pageSize}
              stickyHeader
             // autoHeight={true}
              disableColumnMenu={true}
              disableSelectionOnClick = {true}
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[10, 20, 50, 100]}
              rowCount= {data.totalDocs}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              loading={loading}
              components={{
                  Toolbar: CustomToolbar,
              }}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              localeText={{
                  ...ruRU.props.MuiDataGrid.localeText,
                  ...customRuRu
                }}
          />
      );
  }