import React, {useState, useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
var uniqid = require('uniqid');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    color: '#3f51b5',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    display: 'block',
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  filter_field: {
    flexBasis: '30%',
    display: 'inline'
  },
  filter_operator: {
    flexBasis: '15%',
    display: 'inline'
  },
  filter_value: {
    flexBasis: '40%',
    display: 'inline'
  },
  filter_delete: {
    flexBasis: '15%',
    display: 'inline'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // width: "100%",
    
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button_delete: {
    marginTop: 20
  },
  add_button: {
    marginTop: 36,
  },
}));

export const Filter = (props) => {
  const classes = useStyles();
  const fields  = props.fields;
  const [filterData, setFilterData] = useState([]);
  const [lastUpdatedFilterItem, setLastUpdatedFilterItem] = useState([]);
  

  const RenderFilterItem = (props) => {
     const {id ,field, operator, value} = props.filterItem
     console.log("RenderFilterItem")
      return (
        <Grid item xs={12}>
      <div>
        <div className={classes.filter_field}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Поле для поиска</InputLabel>
            <Select
              native
              value={field}
              onChange={(e) => handleChange(e, id)}
              inputProps={{
                name: 'field',
                id: 'field'.id,
                key: 'operator'.id
              }}
            >
              <option aria-label="Не выбрано" value="" />
              <option value="all_fields" key="all_fields">Во всех полях</option>
              {fields.map((field_data, index)=>{
                return (<option value={field_data.field} key={field_data.field}>{field_data.label}</option>)
                })
              }
              
            </Select>
          </FormControl>
        </div>
        {/* <div className={classes.filter_operator}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Условие</InputLabel>
          <Select
            native
            value={operator}
            onChange={(e) => handleChange(e, id)}
            inputProps={{
              name: 'operator',
              id: 'operator'.id,
              key: 'operator'.id
            }}
          >
            <option aria-label="None" value="" />
            <option value="equal">Равно</option>
            <option value="contains">Содержит</option>
          </Select>
        </FormControl>
        </div> */}
        <div className={classes.filter_value}>
          <TextField
            id={'value'.id}
            label="Текст"
            style={{ margin: 8 }}
            placeholder="Введите текст для поиска"
            key = {'value'.id}
            margin="normal"
            value={value}
            disabled={!field}
            onChange={(e) => handleChange(e, id)}
            autoFocus =  {lastUpdatedFilterItem === id}
            inputProps={{
              name: 'value',
              id: 'value'.id,
            }}
          />
        </div>
        <div className={classes.filter_delete}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button_delete}
              startIcon={<DeleteIcon />}
              onClick = {() => onDeleteClick(id)}
            >
            Удалить
          </Button>
        </div>
      </div>
      </Grid>)
    
}

const onAddClick = () => {
  setFilterData([...filterData, {id: uniqid(), field:"", operator:"equal", value:""}])
}

const onDeleteClick = (id) => {
  const data = filterData.filter(filterItem => filterItem.id !== id);
  setFilterData(data)
}

const handleChange = (event, filterItemId) => {
  setFilterData(filterData.map(filterItem => ( filterItem.id === filterItemId ? {...filterItem,  [event.target.name]:event.target.value} : filterItem)))
  setLastUpdatedFilterItem(filterItemId)
};

const addAllFieldsValues = (filterAllFieldsValues, value) => {
  fields.forEach(fieldData => {
    if (fieldData.field in filterAllFieldsValues){
      filterAllFieldsValues[fieldData.field].push(value)
    }else {
      filterAllFieldsValues[fieldData.field] = [value]
    }
  })
  return filterAllFieldsValues
}

const onFilterChange = () => {
  let filterValues = {}
  filterData.forEach(filterItem => {
    if(filterItem.value){
      if (filterItem.field in filterValues){
        if(filterItem.field == "all_fields"){
          filterValues[filterItem.field] = addAllFieldsValues(filterValues[filterItem.field], filterItem.value)
        } else {
          filterValues[filterItem.field].push(filterItem.value)
        }
      }else {
        if (filterItem.field == "all_fields"){
          filterValues[filterItem.field] = addAllFieldsValues({}, filterItem.value)
        }else {
          filterValues[filterItem.field] = [filterItem.value]
        }
      }
    }
  })
  let filter = {filterValues}
  props.onFilterChange({filter})
}

const onFilterClear =  useCallback(() => {
  setFilterData([])
}, [])

useEffect(() => {
  onFilterChange()
}, [onFilterClear, filterData])

  return (
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className={classes.column}>
        <Typography className={classes.heading}>Фильтр {filterData.length>0? filterData.length: ''}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}></Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
            {filterData.map((filterItem) => (
              <RenderFilterItem 
                filterItem = {filterItem}
                key =  {filterItem.id}
              />
             ))}
         
          <Grid item xs={12}>
            <Button 
              className={classes.add_button}
              variant="contained" 
              color="primary"
              fullWidth
              startIcon={<AddIcon />}
              onClick = {onAddClick}
              >
              Добавить условие
            </Button>
          </Grid>
          
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button 
            size="small"
            onClick = {onFilterClear}
          >
            Очистить
          </Button>
          <Button 
            size="small" 
            color="primary"
            onClick = {onFilterChange}
          >
            Найти
          </Button>
        </AccordionActions>
      </Accordion>
    </div>
  );
}
