import React, {useContext} from 'react'

import DeleteIcon from '@material-ui/icons/Delete';
import {Prompt} from './Prompt'
import {useAPI} from '../hooks/useAPI'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useStyles } from './Styles';
import {ListContext} from '../context/ListContext'

export const DeleteStartup = (props) => {
    const classes = useStyles();
    const [showPrompt, setShowPrompt] = React.useState(false)
    const [runParentEvent, setrRunParentEvent] = React.useState(false)
    const {deleteStartup, deleteStartupFromList} = useAPI()
    const {userRole, startup} = props
    const {listId} = useContext(ListContext);

    const handleOnClick = () => {
        setShowPrompt(true)
    }

    const handleOk = async () => {
        const response = await deleteStartup(startup.id)
        setShowPrompt(false)
        if (response.status == 'ok') {
            props.refreshStartups()
        }
        
       
    }

    const handleDeleteFromList = async () => {
        const response = await deleteStartupFromList(listId, [startup.id])
        setShowPrompt(false)
        if (response.status == 'ok') {
            props.refreshStartups()
        }
        
       
    }

    const handleCancel = () => {
        setShowPrompt(false)
    }



    return(
        <>
        {((userRole == 'admin' || userRole == 'scout') && !startup.deleted)&& <Tooltip title="Удалить стартап">
            <Button className={classes.row_icon} aria-controls="simple-menu" aria-haspopup="true" startIcon={<DeleteIcon />} onClick={handleOnClick}>
            </Button>
        </Tooltip>}
        {showPrompt && !listId && <Prompt
            onYes={handleOk}
            onNo={handleCancel}
            text={`Удалить стартап - ${startup.name}?`}
        />}
        {showPrompt && listId  && <Prompt
            onYes={handleDeleteFromList}
            onNo={handleCancel}
            text={`Удалить стартап - ${startup.name}, из списка?`}
        />}

        </>
    )
}